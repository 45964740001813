import { KeycloakProfile } from 'keycloak-js';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed } from '@angular/core';

export interface UserState {
  user: KeycloakProfile | null;
  fullName?: () => string;
  isAdmin: boolean;
}

const initialState: UserState = {
  user: null,
  isAdmin: false,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(store => ({
    setUser(user: KeycloakProfile) {
      patchState(store, { user });
    },
    clearUser() {
      patchState(store, { user: null });
    },
    setAdmin(bool: boolean) {
      patchState(store, { isAdmin: bool });
    },
  })),
  withComputed(store => ({
    fullName: computed(() => {
      const user = store.user();
      if (!user) return '';

      // Kombiniere Vor- und Nachname, falls vorhanden
      const nameParts = [user.firstName, user.lastName]
        .filter(Boolean) // Entfernt null/undefined/empty strings
        .join(' ')
        .trim();

      // Wenn kombinierter Name existiert, verwende diesen, sonst username
      return nameParts || user.username || null;
    }),
  })),
);

export type UserStore = typeof UserStore;
